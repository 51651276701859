<template>
  <div>
    <load-profile></load-profile>
    <section class="invoice-preview-wrapper">
      <b-row v-if="data.id" class="invoice-preview">
        <b-col cols="12" md="8" xl="9" class="pr-md-0">
          <b-card no-body class="invoice-preview-card">
            <b-card-body class="invoice-padding pb-0">
              <div
                class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
              >
                <div>
                  <div class="logo-wrapper">
                    <h3 class="text-primary invoice-logo ml-0">
                      {{ $t("breadcrumb.transferReceipt") }}
                    </h3>
                  </div>
                </div>

                <div class="mt-md-0 mt-2">
                  <h4 class="invoice-title">
                    <span class="invoice-number">#{{ data.no }}</span>
                  </h4>
                </div>
              </div>
            </b-card-body>

            <hr class="invoice-spacing" />

            <b-card-body class="invoice-padding pt-0">
              <b-row class="invoice-spacing">
                <b-col cols="12" xl="6" class="p-0">
                  <h6>{{ $t("field.transferFrom") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.transferFromName }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.transferFromCode }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.transferFromAddress }}
                  </p>

                  <h6 class="mt-2">{{ $t("field.transferTo") }}:</h6>
                  <h6 class="mb-25">
                    {{ data.transferToName }}
                  </h6>
                  <p class="card-text mb-25">
                    {{ data.transferToCode }}
                  </p>
                  <p class="card-text mb-25">
                    {{ data.transferToAddress }}
                  </p>
                </b-col>

                <b-col
                  xl="6"
                  cols="12"
                  class="p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end"
                >
                  <div>
                    <h6>{{ $t("field.information") }}:</h6>
                    <table>
                      <tbody>
                        <tr>
                          <td class="pr-1">{{ $t("field.shipmentNo") }} :</td>
                          <td>
                            {{ data.lastShipmentNo }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.transferDate") }} :</td>
                          <td>
                            <span class="font-weight-bold">
                              {{ data.transferDate | formatDate("LL") }}
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.status") }} :</td>
                          <td :class="data.status">
                            {{ data.status }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pr-1">{{ $t("field.remark") }} :</td>
                          <td>
                            {{ data.remark }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </b-col>
              </b-row>
            </b-card-body>

            <div class="order-summary">
              <n-table
                class="px-2 order-summary-table"
                ref="table"
                :fields="fields"
                :items="items"
              >
                <template #cell(quantity)="data">
                  {{ data.item.quantity }} {{ data.item.unitOfMeasureCode }}
                </template>

                <template #cell(shippedQuantity)="data">
                  {{ data.item.shippedQuantity }}
                  {{ data.item.unitOfMeasureCode }}
                </template>

                <template #cell(receivedQuantity)="data">
                  {{ data.item.receivedQuantity }}
                  {{ data.item.unitOfMeasureCode }}
                </template>
              </n-table>
            </div>
          </b-card>
        </b-col>

        <b-col cols="12" md="4" xl="3" class="invoice-actions">
          <b-card>
            <n-button-loading
              :variant="data.status == 'Confirmed' ? 'secondary' : 'success'"
              :disabled="data.status == 'Confirmed'"
              :loading="loading"
              block
              @click="openConfimModal"
            >
              {{
                data.transferToCode == user.agentCode
                  ? $t("button.confirmRecieve")
                  : $t("button.confirmShipment")
              }}
            </n-button-loading>
          </b-card>
        </b-col>
      </b-row>
    </section>

    <confirm-modal
      ref="confirmModal"
      :title="$t('alert.areYouSure')"
      :ok-text="
        data.transferToCode == user.agentCode
          ? $t('button.confirmRecieve')
          : $t('button.confirmShipment')
      "
      ok-variant="success"
      :fields="confirmFields"
      :confirm-text="
        data.transferToCode == user.agentCode
          ? $t('alert.confirmRecieveTransfer')
          : $t('alert.confirmShipmentTransfer')
      "
      @submit="confirm"
    />
  </div>
</template>

<script>
import { BRow, BCol, BCard, BCardBody, BBadge, BTr, BTh } from "bootstrap-vue";
import Repository from "@/repositories/RepositoryFactory";
import NButtonLoading from "@/components/NButtonLoading";
import NTable from "@/components/NTable";
import tableFields from "./viewTableFields";
import ConfirmModal from "@/components/ConfirmModal.vue";

const TransferReceiptRepository = Repository.get("transferReceipt");

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BBadge,
    BTr,
    BTh,

    NTable,
    ConfirmModal,
    NButtonLoading,
  },
  computed: {
    user: function () {
      return this.$store.state.profile.username
        ? this.$store.state.profile
        : JSON.parse(localStorage.getItem("userData"));
    },
  },
  data() {
    return {
      loading: false,
      data: {},
      items: [],
      confirmFields: [
        {
          key: "remark",
          label: "field.remark",
          rules: "",
          type: "textarea",
          cols: 12,
        },
      ],
    };
  },
  created() {
    this.show();
  },
  methods: {
    show() {
      this.loading = true;
      TransferReceiptRepository.show(this.$route.params.id)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.items = [...data.lines];
          }
        })
        .catch()
        .then(() => {
          this.loading = false;
        });
    },
    openConfimModal() {
      this.$refs.confirmModal.show();
    },
    confirm(data) {
      TransferReceiptRepository.confirm({
        id: this.data.id,
        remark: data.remark,
      })
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.data = { ...data };
            this.items = [...data.lines];
          }
          this.$refs.confirmModal.hide();
        })
        .catch(() => this.$refs.confirmModal.hideLoading());
    },
  },
  setup() {
    const fields = [...tableFields];

    const resource = "agent-transfer-order";
    const route = "transfer-order";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
</style>
